.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: #19191a;
    width: 40%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 25px;
}

.modal-main p {
    color: #999ca8;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

hr.white {
    color: #ffffff;
    background-color: #ffffff;
    height: 5px
}

.center-align {
    text-align: center;
}